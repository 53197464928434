// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useState } from 'react';
import { Card } from 'antd';

import './HomePage.css';
import MakeAPayment from '../Ledger/modals/MakeAPayment';
import LedgerCurrentBalance from '../Ledger/components/CurrentBalance';

const Ledger: FC = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = (ev: Event) => {
    ev.preventDefault();
    setModalVisible(true);
  };

  const onPaymentSuccess = () => {};

  return (
    <Card className={'home-page-card'} title={'Ledger'}>
      <div>
        Current Balance
        <h5>
          <LedgerCurrentBalance />
        </h5>
        <div>
          <a href={'/primary'}>View/Print Ledger</a>
        </div>
        <div>
          <a href={'#'} onClick={openModal}>
            Make A Payment
          </a>
        </div>
        <MakeAPayment
          onPaymentSuccess={onPaymentSuccess}
          visible={modalVisible}
          onVisibleChange={setModalVisible}
        />
      </div>
    </Card>
  );
};

export default Ledger;
