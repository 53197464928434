import React, { FC } from 'react';
import { useGet } from '../../../hooks/useApi';
import { NSDCamp } from '../../../models/nsd-camp.model';
import { Student } from '../../../models/student.model';

const UpcomingNSDCamps: FC = () => {
  const upcoming = useGet<(NSDCamp & { allowedStudents: Student[] })[]>(
    '/v1/auth/dashboard/nsd-camps/upcoming'
  );

  if (!upcoming.data || !upcoming.data.length) {
    return null;
  }

  return (
    <div
      className={'mb-4'}
      style={{
        backgroundColor: '#e9f6e9',
        padding: '1rem',
        border: '1px solid rgba(10,10,10,.15)',
        fontSize: '16px',
      }}
    >
      <h1 style={{ color: '#703895', fontSize: '18px' }}>
        Non-School Day Camp News!
      </h1>
      <hr className={'mt-3 mb-3'} />
      <p>You have students eligible to enroll in the upcoming Test Camp!</p>

      <div className={'mt-3'}>
        <a href={'/NSDCamps'} className={'btn btn-primary btn-sac'}>
          Go to Non School Day Camp Enrollment
        </a>
      </div>
    </div>
  );
};

export default UpcomingNSDCamps;
