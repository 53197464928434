// src/hooks/useLogout.ts
import { useContextValue } from '../Context';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const { setState } = useContextValue();
  const navigate = useNavigate();

  return () => {
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    setState((prevState) => ({ ...prevState, user: null }));
    navigate('/login');
  };
};
