import React from 'react';
import LoginPage from './pages/Login/LoginPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import HomePage from './pages/Home/HomePage';
import { useFetchUser } from './hooks/useFetchUser';
import PrimaryGuardian from './pages/PrimaryGuardian/PrimaryGuardian';

import { default as AdditionalGuardianList } from './pages/AdditionalGuardian/List';
import AdditionalGuardian from './pages/AdditionalGuardian/AdditionalGuardian';
import NewAdditionalGuardian from './pages/AdditionalGuardian/New';

import { default as EmergencyContactList } from './pages/EmergencyContact/List';
import EmergencyContact from './pages/EmergencyContact/EmergencyContact';
import NewEmergencyContact from './pages/EmergencyContact/New';

import { default as StudentList } from './pages/Student/List';
import Student from './pages/Student/Student';
import Ledger from './pages/Ledger/Ledger';
import StudentSchedule from './pages/StudentSchedule/StudentSchedule';
import NonSchoolDayCampEnrollment from './pages/NonSchoolDayCampEnrollment/NonSchoolDayCampEnrollment';

const App: React.FC = () => {
  useFetchUser();

  return (
    <div id="App">
      <Router>
        <Routes>
          <Route path="/login" Component={LoginPage} />
          <Route
            path="/"
            element={
              <AuthGuard>
                <HomePage />
              </AuthGuard>
            }
          />
          <Route
            path="/primary"
            element={
              <AuthGuard>
                <PrimaryGuardian />
              </AuthGuard>
            }
          />
          <Route
            path="/additional-guardians"
            element={
              <AuthGuard>
                <AdditionalGuardianList />
              </AuthGuard>
            }
          />
          <Route
            path="/additional-guardian/new"
            element={
              <AuthGuard>
                <NewAdditionalGuardian />
              </AuthGuard>
            }
          />
          <Route
            path="/additional-guardian/:id"
            element={
              <AuthGuard>
                <AdditionalGuardian />
              </AuthGuard>
            }
          />
          <Route
            path="/emergency-contacts"
            element={
              <AuthGuard>
                <EmergencyContactList />
              </AuthGuard>
            }
          />
          <Route
            path="/emergency-contact/new"
            element={
              <AuthGuard>
                <NewEmergencyContact />
              </AuthGuard>
            }
          />
          <Route
            path="/emergency-contact/:id"
            element={
              <AuthGuard>
                <EmergencyContact />
              </AuthGuard>
            }
          />
          <Route
            path="/students"
            element={
              <AuthGuard>
                <StudentList />
              </AuthGuard>
            }
          />
          <Route
            path="/student/new"
            element={
              <AuthGuard>
                <Student />
              </AuthGuard>
            }
          />
          <Route
            path="/student/:id"
            element={
              <AuthGuard>
                <Student />
              </AuthGuard>
            }
          />
          <Route
            path="/student/:id/schedule"
            element={
              <AuthGuard>
                <StudentSchedule />
              </AuthGuard>
            }
          />
          <Route
            path="/ledger"
            element={
              <AuthGuard>
                <Ledger />
              </AuthGuard>
            }
          />
          <Route
            path="/NSDCamps"
            element={
              <AuthGuard>
                <NonSchoolDayCampEnrollment />
              </AuthGuard>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
