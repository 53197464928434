import { Input, Modal, Skeleton } from 'antd';
import { FC, useState } from 'react';
import { useGet, usePost } from '../../../hooks/useApi';
import { Student } from '../../../models/student.model';

const PaymentModal: FC<{
  id: number;
  student: Student;
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
}> = ({ id, student, visible, onVisibleChange }) => {
  const upcoming = useGet<any>(`/v1/auth/dashboard/nsd-camps/${id}/complete`);
  const post = usePost<any, any>('/v1/auth/dashboard/payment');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState(0);

  const canSubmit = () => {
    return total > 0;
  };

  const onOk = () => {};

  const dismiss = () => {
    onVisibleChange(false);
  };

  const Content = () => (
    <>
      <hr className={'mt-3 mb-3'} />

      <p>
        <b>{`Student's Name:`} </b> {student.firstName} {student.lastName}
      </p>
      <p>
        <b>Grade: </b> {student.Grade?.grade}
      </p>

      {upcoming.data?.NSDCampDays.map((NSDCampDay: any) => (
        <>{JSON.stringify(NSDCampDay)}</>
      ))}

      <hr className={'mt-3 mb-3'} />

      <table className={'w-100'}>
        <tr>
          <td style={{ width: '50px' }}>Total:</td>
          <td>
            <Input type={'number'} value={total} disabled />
          </td>
        </tr>
      </table>
    </>
  );

  return (
    <Modal
      title="Choose Your Payment"
      confirmLoading={post.isLoading}
      onOk={onOk}
      okText="Pay Now"
      visible={visible}
      onCancel={dismiss}
      okButtonProps={{ disabled: !canSubmit(), id: 'pay-now' }}
      className={'make-a-payment-modal'}
    >
      {upcoming.data ? <Content /> : <Skeleton active />}
    </Modal>
  );
};

export default PaymentModal;
